<template>
  <div class="v-file-upload" v-if="selectedFile">
    <div class="v-file-upload__content">
        <img class="v-file-upload__uploaded-file-icon" src="@/assets/images/pdf-icon.png" alt="pdf-icon"
        @click="previewFile" />
      <div class="v-file-upload__text">
        <div class="v-file-upload__title">{{ selectedFile?.[namePropertyField] || selectedFile.name }}</div>
        <div class="v-file-upload__size" v-if="showSize && selectedFile?.size">
          {{ Number.isInteger(selectedFile.size) ? `${(selectedFile.size / (1024 * 1024)).toFixed(2)} МБ`  : selectedFile.size }}
        </div>
      </div>
    </div>

    <div
      class="v-file-upload__delete"
      v-if="deleteBtn"
      @click="deleteFile"
    >
      <img src="@/assets/images/icons/delete-red.svg" alt="delete" />
    </div>

    <a :href="selectedFile.filepath" download v-else>
      <img src="@/assets/images/icons/download-blue.svg" alt="download" />
    </a>
  </div>
  <div v-else>
    <div class="v-file-upload__upload-files-btn">
      <input @change="changeFile" type="file" :accept="accept" :id="id"/>
      <img src="@/assets/images/icons/upload.svg" alt="upload" />
      Загрузить файл
    </div>
  </div>
</template>

<script setup>
import { integer } from "@vuelidate/validators";
import { ref, onMounted, watch } from "vue";

const emit = defineEmits(["update:modelValue", "changeFile", "deleteFile"]);
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: ''
  },
  modelValue: {
    type: Object,
    required: false,
  },
  file: Object,
  accept: {
    type: String,
    required: false,
  },
  deleteBtn: {
    type: Boolean,
    default: false,
  },
  showSize: {
    type: Boolean,
    default: true,
  },
  namePropertyField: {
    type: String,
    required: false,
  },
});

const selectedFile = ref(null);
const changeFile = (e) => {
  emit("changeFile", e);
  const file = e.target?.files?.[0];
  console.log('file',file)
  selectedFile.value = file
  emit("update:modelValue", selectedFile.value);

};

const deleteFile = () => {
//   selectedFile.value = null
  emit("deleteFile", props?.id)
//   emit("update:modelValue", null)
}

const previewFile = () => {
  if (typeof props?.modelValue === 'string') {
    const viewFileURL = props?.modelValue
    let link = document.createElement('a');
    link.setAttribute('type', '_blank')
    // link.setAttribute('name', 'Политика обработки персональных данных')
    // link.setAttribute('id', 'Политика обработки персональных данных')
    link.href = `${viewFileURL}`
    link.click();
    link.remove();
  } else {
    const viewFileURL = URL.createObjectURL(props?.modelValue)
    let link = document.createElement('a');
    link.setAttribute('type', '_blank')
    // link.setAttribute('name', 'Политика обработки персональных данных')
    // link.setAttribute('id', 'Политика обработки персональных данных')
    link.href = `${viewFileURL}`
    link.click();
    link.remove();
  }
}

watch(props.modelValue, () => {
    // selectedFile.value = props.modelValue;
})
onMounted(() => {
    console.log('VFILEUPLOAD props',props)
  selectedFile.value = props.modelValue;
});
</script>

<style scoped lang="scss">
.v-file-upload {
  height: 56px;
  padding: 10px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $light;

  &__content {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 12px;
  }

  &__title {
    width: 100px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__size {
    margin-top: 4px;
    color: $gray;
    font-size: 12px;
  }

  &__delete {
    cursor: pointer;
  }

  &__upload-files-btn {
    position: relative;
    width: 187px;
    height: 56px;
    padding: 0 23.5px;
    border-radius: 8px;
    background-color: $primary-blue;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    img {
      width: 24px;
      margin-right: 8px;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }
  }

  &__uploaded-file-icon {
    display: block;
    width: 24px;
    height: 24px;
    object-fit: contain;
    flex: 0 0 auto;
    cursor: pointer;
  }
}
</style>
